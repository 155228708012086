import React from "react";
import fblogo from "../../images/social-icons/facebook.svg";
import twitterlogo from "../../images/social-icons/twitter-new.png";
import instagramlogo from "../../images/social-icons/instagram.svg";
import linkedinlogo from "../../images/social-icons/linkedin.svg";

const SocialIcons = () => {
  return (
    <ul className="list-unstyled d-flex m-0 p-0">
      <li>
        <a
          className="mr-2 ml-2"
          href="https://www.facebook.com/BaashyaamConstructions"
        >
          <img height={22} width={22} src={fblogo} alt="" />
        </a>
      </li>
      <li>
        <a className="mr-2 ml-2" href="https://twitter.com/BaashyaamGroup">
          <img height={22} width={22} src={twitterlogo} alt="" />
        </a>
      </li>
      <li>
        <a
          className="mr-2 ml-2"
          href="https://www.instagram.com/baashyaam_constructions/"
        >
          <img height={22} width={22} src={instagramlogo} alt="" />
        </a>
      </li>
      <li>
        <a
          className="mr-2 ml-2"
          href="https://www.linkedin.com/company/baashyaam-constructions"
        >
          <img height={22} width={22} src={linkedinlogo} alt="" />
        </a>
      </li>
    </ul>
  );
};

export default SocialIcons;
