import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import NullComponent from "../common/NullComponent";

function OngoingList({ RenderItem = NullComponent }) {
  const data = useOngoingList();
  const projects = data.allOngoingJson.edges;
  return projects.map(({ node }) => <RenderItem {...node} key={node.id} />);
}

export const useOngoingList = () =>
  useStaticQuery(graphql`
    query {
      allOngoingJson(
        filter: { is_active: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            id
            title
            slug
            ribbon_text
            banner {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            short_desc
            footer_desc
          }
        }
      }
    }
  `);

export default OngoingList;
